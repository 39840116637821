<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card class="text-center">
          <v-card-title class="justify-center mb-2">
            <h1 class="text-h5 text-uppercase font-weight-bold text-center">
              Página não encontrada!
            </h1>
          </v-card-title>
          <v-card-subtitle>
            <p class="subheading justify-center">
              Desculpe, a página que você está procurando não existe.
            </p>
          </v-card-subtitle>
          <v-card-actions class="justify-center">
            <v-btn color="secondary" large @click="goHome">
              Voltar para a página inicial
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goHome() {
      // Navega para a página inicial (pode ser alterado se necessário)
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1976d2; /* Cor azul para o título */
  margin: 20px 0;
}

.subheading {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.v-card {
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.v-btn {
  padding: 12px 24px;
  font-size: 1rem;
}

.v-card-actions {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
