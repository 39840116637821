<template>
  <div
    :class="[
      { 'sale-page-one': $route.path == '/sale-page-one' },
      { 'sale-page-two': $route.path == '/sale-page-two' },
    ]"
  >
    <div
      class="secondary white--text top-bar"
      :class="{ 'd-none': $route.path == '/home-two' }"
    >
      <v-container>
        <!--Menu mobile-->

        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title>
            <div class="mr-2 d-md-block d-none">
              <span class="text-md">
                <!-- Horário de atendimento da Loja -->
                <v-btn color="white" text rounded @click="makeCalling()">
                  <v-icon medium size="20px" color="white">mdi-phone</v-icon>
                  934 66 00 03
                </v-btn>
                <span> </span>

                <span>
                  <v-icon medium size="20px" color="white">mdi-clock</v-icon>
                  (De 2ª à 6ª Feira, das 8h às 17h e aos Sábados, das 8h às
                  12h).
                </span>
              </span>
            </div>
          </v-toolbar-title>
          <v-toolbar-title>
            <!-- <span title="Apoio ao cliente pelo Whatsapp:9346 60 003" target="_blank" href="https://api.whatsapp.com/send?1=pt_BR&phone=244934660003&text=Ol%C3%A1!%20Tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20vosso%20Aplicativo." class="text-md font-weight-bold">
              Whatsapp:
            </span> -->
            <!-- <v-btn title="Apoio ao cliente pelo Whatsapp:9346 60 003" target="_blank" href="https://api.whatsapp.com/send?1=pt_BR&phone=244934660003&text=Ol%C3%A1!%20Tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20vosso%20Aplicativo." class="me-2 white--text" elevation="0" color="primary">
              <v-icon medium size="20px" color="white"> mdi-whatsapp </v-icon>
            </v-btn> -->
            <v-btn
              target="_blank"
              text
              rounded
              href="https://api.whatsapp.com/send?1=pt_BR&phone=244934660003&text=Ol%C3%A1!%20Tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20vosso%20Aplicativo."
              class="me-2"
            >
              <v-icon medium size="25px" color="white"> mdi-whatsapp </v-icon>
            </v-btn>
          </v-toolbar-title>
        </div>
      </v-container>
    </div>
    <v-app-bar
      height="72"
      app
      scroll-off-screen
      class="sub-topbar"
      color="white py-1"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/">
              <v-avatar
                width="170"
                title="Mutue Vendas Online"
                height="98"
                tile
              >
                <img
                  title="Mutue Vendas Online"
                  src="@/assets/images/MVO.png"
                  alt=""
                />
              </v-avatar>
            </router-link>
            <div class="dropdown-ecommerce">
              <HeaderNavbar />
            </div>
          </v-toolbar-title>
          <v-col cols="12" md="7">
            <div
              v-if="$route.path == '/home-two'"
              class="search-bar d-flex p-relative"
            >
              <v-text-field
                placeholder="Pesquisar por..."
                filled
                rounded
                hide-details
                dense
                v-model="keyword"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>

              <v-btn
                color="primary"
                class="text-capitalize search-bar-dropdown px-10 font-600"
              >
                Search
              </v-btn>
            </div>

            <div v-else class="search-bar d-flex p-relative">
              <v-text-field
                class=""
                placeholder="Pesquisar por..."
                filled
                rounded
                dense
                v-model="keyword"
                @keyup.enter="getKeyword()"
                prepend-inner-icon="mdi-magnify"
              >
              </v-text-field>
              <!-- <v-row class="justify-left text-left">
                <v-col cols="12">
                  <v-alert type="red" dismissible dense v-if="!keyword">
                    <v-icon>mdi-chevron-left</v-icon> Nome do produto a
                    pesquisar
                  </v-alert>
                </v-col>
              </v-row> -->

              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <!-- <v-btn
                    color="white"
                    class="text-capitalize search-bar-dropdown"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Todas Categorias
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn> -->
                  <v-btn
                    :disabled="!keyword"
                    @click="getKeyword()"
                    color="primary"
                    rounded
                    class="text-capitalize search-bar-dropdown"
                    elevation="0"
                  >
                    Pesquisar
                  </v-btn>
                </template>
                <!-- <v-list>
                  <v-list-item v-for="(item, index) in items" :key="index">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list> -->
                <v-list>
                  <v-list-item
                    link
                    v-for="product in filteredProducts"
                    :key="product.uuid"
                  >
                    <router-link
                      class="custom-link"
                      :to="{
                        name: 'ProdutoDetalhes',
                        params: { produto_id: product.uuid },
                      }"
                    >
                      <v-list-item-avatar rounded>
                        <v-img
                          :src="SERVER + product.imagem_produto"
                          :alt="
                            product.designacao +
                            'por apenas ' +
                            product.pvp +
                            ' AKZ'
                          "
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          limitText(product.designacao)
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <!-- <div class=""> -->
          <div class="d-md-block d-none">
            <!-- <v-btn v-if="token" elevation="0" fab small class="mr-3" color="grey lighten-2">
                        <v-icon>mdi-account</v-icon>
                    </v-btn> -->
            <v-menu
              class="hidden-sm-and-down"
              color="grey lighten-2"
              v-if="token"
              left
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  v-on="on"
                  class="text-capitalize hidden-sm-and-down"
                >
                  {{ user.name }}
                  <v-icon>{{
                    on ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </v-btn>
              </template>

              <v-list class="mt-12">
                <v-list-item link :to="{ name: 'ClientePerfil' }">
                  <v-list-item-title>
                    <v-icon>mdi-account</v-icon>
                    Meu Perfil
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="showDialogTerminarSessao()">
                  <v-list-item-title>
                    <v-icon color="error">mdi-power</v-icon>
                    Terminar sessão
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-badge
              v-if="token"
              bordered
              color="error"
              :content="totalCarrinho == 0 ? '0' : totalCarrinho"
              overlap
            > -->
            <!-- <v-btn v-if="token" elevation="0" :to="{ name: 'ClienteNotificacoes' }" fab title="Ver Minhas Notificações" small class="mr-3" color="grey lighten-2">
              <v-icon> mdi-bell</v-icon>
            </v-btn> -->
            <!-- </v-badge> -->

            <v-btn
              v-if="token"
              elevation="0"
              :to="{ name: 'ClienteDashboard' }"
              fab
              title="Ver Minha Dashboard"
              small
              class="mr-3"
              color="grey lighten-2"
            >
              <v-icon>mdi-view-dashboard</v-icon>
            </v-btn>

            <v-btn
              v-if="!token"
              elevation="0"
              :to="{ name: 'PortalLogin' }"
              fab
              title="Iniciar sessão"
              small
              class="mr-3"
              color="grey lighten-2"
            >
              <v-icon>mdi-login</v-icon>
            </v-btn>

            <v-badge
              bordered
              color="error"
              :content="totalCarrinho == 0 ? '0' : totalCarrinho"
              overlap
            >
              <v-btn
                elevation="0"
                fab
                color="grey lighten-2"
                small
                title="Ver meu Carrinho"
                :to="{ name: 'CarrinhoResumo' }"
              >
                <v-icon color="">mdi-cart</v-icon>
              </v-btn>
            </v-badge>
          </div>
          <!-- </div> -->
        </div>
      </v-container>
    </v-app-bar>
    <v-container>
      <!-- <div style="margin-right:-6000px">
        <h1>XXXXXXX</h1>
      </div> -->
      <v-menu
        v-if="filteredProducts.length > 0"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="keyword != ''"
            text
            outlined
            v-on="on"
            v-model="selectedProduct"
            class="text-capitalize hidden-sm-and-down"
          >
            Ver Produtos({{ filteredProducts.length }})
            <v-icon>{{
              on ? "mdi-chevron-right" : "mdi-chevron-right"
            }}</v-icon>
          </v-btn>
        </template>

        <v-row name="list-products">
          <v-col cols="12">
            <transition-group name="fade" mode="out-in">
              <v-card
                elevation="0"
                width="650"
                v-for="produto in filteredProducts"
                :key="produto.id"
              >
                <v-card-text
                  class="ps-6 d-flex justify-space-between align-center flex-wrap"
                >
                  <router-link
                    class="custom-link"
                    :to="{
                      name: 'ProdutoDetalhes',
                      params: { produto_id: produto.uuid },
                    }"
                  >
                    <v-list-item>
                      <v-list-item-avatar
                        size="60"
                        :title="produto.designacao"
                        rounded
                      >
                        <v-img
                          :alt="produto.designacao"
                          :src="SERVER + produto.imagem_produto"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-uppercase">{{
                          produto.designacao
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          class="text-subtitle2 font-weight-medium"
                        >
                          {{ formatePrice(produto.pvp) }}
                          AKZ (IVA incluido)</v-list-item-subtitle
                        >
                        <v-list-item-subtitle>
                          <v-rating
                            :value="
                              produto.classificacao.num_classificacao == null ||
                              !produto.classificacao.num_classificacao
                                ? 5
                                : parseInt(
                                    produto.classificacao.num_classificacao
                                  )
                            "
                            color="amber"
                            background-color="amber"
                            dense
                            half-increments
                            readonly
                            size="12"
                          ></v-rating>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </v-card-text>
                <v-divider color="grey"></v-divider>
              </v-card>
            </transition-group>
          </v-col>
        </v-row>
      </v-menu>
    </v-container>

    <!-- <v-navigation-drawer v-model="drawer" fixed temporary right width="320" class="z-999">
      <div class="secondary-siebar-content">
        <v-list-item>
          <v-list-item-avatar class="mr-0">
            <v-icon color="">mdi-shopping-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="">{{ getCartProducts.length }} Items</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="" @click="drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="getCartProducts.length >= 1">
          <div v-for="(n, index) in getCartProducts" :key="index">
            <div class="cart-item d-flex justify-space-between align-center px-2 py-3">
              <div class="d-flex flex-column align-center">
                <v-btn @click="addCartx(n)" class="mx-2" fab outlined dark x-small color="primary">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <span>{{ n.qty }}</span>
                <v-btn @click="removeCartx(n)" class="mx-2" fab outlined dark x-small color="primary">
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
              </div>
              <v-avatar tile size="76">
                <img :src="n.img" alt="" />
              </v-avatar>
              <div class="col-5">
                <h5 class="text-truncate">{{ n.title }}</h5>
                <p class="text-tiny">${{ n.amount }} x {{ n.qty }}</p>
                <h5 class="primary--text">{{ n.amount * n.qty }}</h5>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div v-else>
          <div class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10">
            <v-avatar size="90" tile class="mb-8">
              <img src="@/assets/images/empty-cart.png" alt="" />
            </v-avatar>
            <p class="grey--text text--darken-2 px-5 mx-10 text-center">
              Your shopping bag is empty. Start shopping
            </p>
          </div>
        </div>
      </div>
      <template v-slot:append v-if="getCartProducts.length >= 1">
        <div class="pa-2">
          <v-btn to="/checkout-alternative" class="text-capitalize mb-3" block color="primary">
            Checkout Now (${{ cartTotal }})
          </v-btn>
          <v-btn to="/cart" class="text-cappitalise" outlined block color="primary">
            View Cart
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

     -->
    <v-app-bar
      class="navbar white"
      :class="{ 'd-none': $route.path == '/sale-page-two' }"
    >
      <div class="container">
        <div class="d-flex justify-space-between align-center">
          <v-btn
            elevation=""
            color="grey lighten-2"
            class="text-capitalize"
            :class="{ 'v-hidden': $route.path == '/home-two' }"
            @click="toggleNavbar"
            v-on-clickaway="away"
          >
            <v-icon left> mdi-view-dashboard </v-icon>
            Categorias
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
          <!-- <h5>
            {{ filteredProducts }}
          </h5> -->
          <div
            id="navbar-toggle-dropdown"
            class="navbar-toggle-dropdown p-absolute"
            :class="{ open: isToggleNavbar }"
          >
            <Navbar />
          </div>
          <!-- <div class="justify-left">
            <h3>AAAAAAAAAAA</h3>
          </div> -->
          <div>
            <ul
              class="navbar-nav navigation-navbar text-uppercase d-flex flex-row"
            >
              <li class="nav-item me-3">
                <router-link class="nav-link" :to="{ name: 'QuemSomos' }">
                  <p class="mb-0">Quem Somos</p>
                </router-link>
              </li>

              <li class="nav-item me-3">
                <router-link class="nav-link" :to="{ name: 'FAQS' }">
                  <p class="mb-0">Perguntas Frequentes</p>
                </router-link>
              </li>

              <li class="nav-item me-3">
                <router-link class="nav-link" :to="{ name: 'ContactosIndex' }">
                  <p class="mb-0 text-uppercase">Fale Connosco</p>
                </router-link>
              </li>
            </ul>
            <!-- <li class="nav-item me-3">
                <a class="nav-link active" href="#">Início</a>

                <ul>
                  <li>
                    <router-link to="/">
                      <p class="mb-0">Homepage One</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-two">
                      <p class="mb-0">Grocery</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-three">
                      <p class="mb-0">Niche Market 1</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/home-four">
                      <p class="mb-0">Niche Market 2</p>
                    </router-link>
                  </li>
                </ul>
              </li> -->
            <!-- <li class="nav-item me-3">
                <a class="nav-link" href="#">Pages</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Sale Page</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/sale-page-one">
                            <p class="mb-0">Version 1</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sale-page-two">
                            <p class="mb-0">Version 2</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Vendor</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-vendors">
                            <p class="mb-0">All Vendors</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/vendor-store">
                            <p class="mb-0">Vendor Store</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Product</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/search-product">
                            <p class="mb-0">Product Grid/Search</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/single-product">
                            <p class="mb-0">Product List/Search</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/cart">
                            <p class="mb-0">Cart</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/checkout-alternative">
                            <p class="mb-0">Checkout Alternative</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="#">User Account</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Address</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/add-address">
                            <p class="mb-0">Add Address</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/list-address">
                            <p class="mb-0">List Address</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Orders</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/order-list">
                            <p class="mb-0">Order List</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/order-details">
                            <p class="mb-0">Order Details</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Profile</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/view-profile">
                            <p class="mb-0">View Profile</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/edit-profile">
                            <p class="mb-0">Edit Profile</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Support Tickets</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-tickets">
                            <p class="mb-0">All Tickets</p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/ticket-details">
                            <p class="mb-0">Ticket Details</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <router-link to="/wishlist">
                      <p class="mb-0">Wishlist</p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link" href="#">Vendor Account</a>
                <ul>
                  <li>
                    <router-link to="/dashboard">
                      <p class="mb-0">Dashboard</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-list">
                      <p class="mb-0">Product List</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/edit-product">
                      <p class="mb-0">Edit Product</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/profile">
                      <p class="mb-0">Profile</p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <router-link class="nav-link" to="/order-list">
                  <p class="mb-0">Rastrear Meus pedidos</p>
                </router-link>
              </li> -->
            <!-- <li class="nav-item">
                <router-link class="nav-link" to="/landing">
                  <p class="mb-0">Landing Page</p>
                </router-link>
              </li> -->
            <!-- </ul> -->
          </div>
        </div>
      </div>

      <v-dialog name="dialog-welcome" v-model="dialogWelcome" max-width="420">
        <v-card>
          <v-card-title
            class="secondary white--text text-h8 text-uppercase"
            style="margin-bottom: -20px"
          >
            BOAS VINDAS
            <v-spacer />
            <!-- <v-btn
            color="white darken-1"
            text
            rounded
          >
            <v-icon color="white" btn size="30px">mdi-close</v-icon>
          </v-btn> -->
          </v-card-title>
          <!-- <v-divider /> -->
          <v-card-text>
            <div class="text-center mt-6">
              <v-icon color="primary" size="80px"
                >mdi-information-outline</v-icon
              >
            </div>
            <v-row class="text-left mt-2 text-center text-h6">
              Caro Cliente! Seja bem vindo a nossa loja. Aqui poderá encontrar
              todos os produtos de que necessita no conforto do seu lar e nós
              levamos até si.
            </v-row>
          </v-card-text>
          <!-- <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="dialogTerminarSessao = false"
          >
            Fechar
          </v-btn>

          <v-btn color="primary darken-1" text @click="logoutCliente()">
            Sim
          </v-btn>
        </v-card-actions> -->
        </v-card>
      </v-dialog>

      <v-dialog
        name="dialog-terminar-sessao"
        v-model="dialogTerminarSessao"
        max-width="300"
      >
        <v-card>
          <v-card-text class="text-center">
            <v-icon color="secondary" class="mt-2" size="60"
              >mdi-information-outline</v-icon
            >
            <h5 class="mt-2">Pretende realmente terminar sessão?</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="dialogTerminarSessao = false"
            >
              Fechar
            </v-btn>
            <v-btn color="secondary darken-1" text @click="logoutCliente()">
              Sim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        name="dialog-logout-sucesso"
        v-model="dialogLogoutSucesso"
        max-width="300"
        persistent
      >
        <v-card>
          <v-card-text class="text-center">
            <v-icon color="success" class="mt-2" size="50"
              >mdi-check-circle</v-icon
            >
            <h6 class="mt-2">Sessão terminada com sucesso!</h6>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderNavbar from "@/views/home/header-navbar";
import Navbar from "@/views/home/navbar";
import { directive as onClickaway } from "vue-clickaway";
import axios from "axios";
import { barramento } from "../../barramento";
import { BASE_URL, LOGIN_URL, SERVIDOR } from "../../configs/api";
export default {
  name: "BaseAppBar",
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    HeaderNavbar,
    Navbar,
  },

  computed: {
    ...mapGetters(["getCartProducts"]),
    cartTotal() {
      let total = 0;
      this.getCartProducts.forEach((product) => {
        total += product.amount * product.qty;
      });
      return total;
    },
    filteredProducts() {
      return this.produtos.filter((product) =>
        product.designacao.toLowerCase().includes(this.keyword.toLowerCase())
      );
    },
  },
  data: () => ({
    group: null,
    SERVER: SERVIDOR,
    menu: false,
    selectedProduct: null,
    userLogado: null,
    shortName: "",
    dialogNoInternetAcess: false,
    userName: "",
    produtos: [],
    dialogLogoutSucesso: false,
    dialogTerminarSessao: false,
    dialogWelcome: false,
    user: {
      cliente: {
        endereco: null,
      },
    },
    dialogUserPerfil: false,
    totalCarrinho: 0,
    token: "",
    userLogado: {
      token: "",
      user: {},
    },
    loading: false,
    overlay: false,
    alert: {
      text: "",
      type: "",
      text2: "",
      type2: "",
    },
    user: {},
    keyword: "",
    cartCount: 0,
    colorNav: false,
    dialog: false,
    isToggleNavbar: false,
    items: [
      // { title: "Car" },
      // { title: "Clothes" },
      // { title: "Electronics" },
      // { title: "Laptop" },
    ],
    itemTwo: ["Foo", "Bar", "Fizz", "Buzz"],
    drawer: false,
    group: null,
  }),
  watch: {
    keyword: function () {
      // Usando debounce para aguardar um curto período após a última entrada do usuário
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.fetchProducts(); // Chama a função para buscar produtos na API
      }, 500); // Ajuste o intervalo conforme necessário
    },
  },
  mounted() {
    var TOKEN_SESSAO = localStorage.getItem("TOKEN");
    this.token = TOKEN_SESSAO;
    // alert(TOKEN_SESSAO == null);
    if (TOKEN_SESSAO != null) {
      this.getUserAuth();
      this.getTotalProdutosCarrinho();
    }
    // else {
    //   this.user.cliente.endereco = null;
    // }
  },
  created() {
    barramento.$on("adicionarCarrinho", (evento) => {
      this.getTotalProdutosCarrinho();
    });
    // this.dialogWelcome = true;
    // alert(JSON.stringify(this.token));
    // setTimeout(() => {
    //   this.dialogWelcome = false;
    // }, 7000);
  },
  methods: {
    makeCalling() {
      let telefone = "934660003";
      window.open("tel:" + telefone);
    },
    limitText(texto, limite = 45) {
      if (texto.length > limite) {
        return texto.substring(0, limite) + "...";
      }
      return texto;
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    redirectToProductPage(produto) {
      this.$router.push({
        name: "ProdutoDetalhes",
      });
    },
    fetchProducts() {
      // this.loading = true;
      if (this.keyword.length >= 3) {
        this.$axios
          .get(BASE_URL + "/produtos/pesquisar/" + this.keyword)
          .then((response) => {
            this.produtos = response.data;
            // setTimeout(() => {
            //   this.loading = false;
            // }, 50);
          })
          .catch((error) => {
            // setTimeout(() => {
            //   this.loading = false;
            // }, 100);
          });
      }
    },
    getFirstName(name) {
      const indexOfSpace = name.indexOf(" ");
      if (indexOfSpace !== -1) {
        this.shortName.slice(0, indexOfSpace);
      } else {
        return this.shortName;
      }
    },

    isPesquisarRota() {
      // Verifique se a rota atual contém '/portal/page/produtos/pesquisar/'
      return this.$route.path.includes("/portal/page/produtos/pesquisar/");
    },
    getFirstName() {
      // Divide a string em palavras usando o espaço como delimitador
      let userName = "";
      setTimeout(() => {
        userName = this.user.name;
      }, 1200);
      if (userName.includes("")) {
        const words = userName.split(" ");
        // Pega o primeiro elemento do array de palavras
        this.userName = words[0];
        // return words[0];
      } else {
        this.userName = this.user.name;
      }
    },

    logoutCliente() {
      this.loading = true;
      this.dialogTerminarSessao = false;
      const url = `${LOGIN_URL}/user/logout`;
      axios
        .post(url)
        .then((response) => {
          this.dialogLogoutSucesso = true;
          // console.log(response.data);
          if (
            localStorage.getItem("TOKEN") ||
            localStorage.getItem("TOKEN") ||
            sessionStorage.removeItem("USER_LOGADO") ||
            localStorage.removeItem("userLogado")
          ) {
            sessionStorage.removeItem("TOKEN");
            sessionStorage.removeItem("USER_LOGADO");
            localStorage.removeItem("userLogado");
            localStorage.removeItem("TOKEN");
          }
          setTimeout(() => {
            this.$router.push({
              name: "/",
            });
            this.loading = false;
            this.dialogLogoutSucesso = false;
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        })
        .catch((error) => {
          if (
            localStorage.getItem("TOKEN") ||
            localStorage.getItem("TOKEN") ||
            sessionStorage.removeItem("USER_LOGADO") ||
            localStorage.removeItem("userLogado")
          ) {
            sessionStorage.removeItem("TOKEN");
            sessionStorage.removeItem("USER_LOGADO");
            localStorage.removeItem("userLogado");
            localStorage.removeItem("TOKEN");
            this.dialogLogoutSucesso = true;
          }

          setTimeout(() => {
            this.$router.push({
              name: "/",
            });
            this.loading = false;
            this.dialogLogoutSucesso = false;
          }, 4500);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    },

    showDialogTerminarSessao() {
      this.dialogTerminarSessao = true;
    },

    getUserAuth() {
      // const userLogadoString = localStorage.getItem("userLogado");
      // if (userLogadoString) {
      //     this.userLogado = JSON.parse(userLogadoString);
      // }
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/user/meAuth`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.user = response.data;
        });
    },
    ...mapActions(["addCart", "removeCart"]),
    showDialogUserPerfil() {
      this.dialogUserPerfil = true;
    },
    getTotalProdutosCarrinho() {
      this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      if (TOKEN_SESSAO) {
        const url = `${BASE_URL}/carrinho/get/my/produtos`;
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + TOKEN_SESSAO,
            },
          })
          .then((response) => {
            this.totalCarrinho = response.data.data.produtos.length;
            setTimeout(() => {
              this.loading = false;
            }, 1500);
          })
          .catch((error) => {
            console.log(`error ${error}`);
            setTimeout(() => {
              this.loading = false;
            }, 1500);
          });
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.totalCarrinho = 0;
      }
    },

    getKeyword() {
      let search = this.keyword;

      if (this.isPesquisarRota()) {
        this.$router.push({
          path: "/portal/page/produtos/pesquisa/" + search,
        });
      } else {
        if (search.length >= 1) {
          this.$router.push({
            path: "/portal/page/produtos/pesquisar/" + search,
          });
          // this.$router.push({ path: '/portal/page/produtos/pesquisar/', params: { search }, query: { search } })
        }
      }
    },
    toggleNavbar() {
      if (this.$route.path == "/") {
        this.isToggleNavbar = false;
      } else {
        this.isToggleNavbar = !this.isToggleNavbar;
      }
    },
    away() {
      this.isToggleNavbar = false;
    },
    removeCartx(item) {
      this.removeCart(item);
    },
    addCartx(item) {
      this.addCart(item);
    },
  },
};
</script>

<style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;

.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}

.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}

.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;

      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}

.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;

      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}

.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;

  .dropdown-ecommerce {
    display: none;
  }

  &.v-app-bar--fixed {
    position: unset;
  }

  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;

    .dropdown-ecommerce {
      display: block;
    }
  }

  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;

  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.search-bar {
  ::v-deep .v-input__slot {
    border: 1px solid rgb(218, 225, 231);
    background-color: #fff !important;
  }

  .search-bar-dropdown {
    height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;
    border-radius: 22px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid rgb(218, 225, 231) !important;
    box-shadow: none !important;

    @media (max-width: $md) {
      display: none;
    }
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;

  &.open {
    display: block;
    top: 64px;
  }
}

.v-hidden {
  visibility: hidden;
}

.empty-cart-sidebar {
  height: 80vh;
}
</style>
