<template>
  <!-- <div> -->
  <v-container class="mb-60" name="produtos-por-categoria">
    <!-- <div v-if="loading" class="mt-12 mb-12 text-center">
        <v-progress-circular size="60" indeterminate color="primary" />
      </div> -->

    <v-row>
      <v-card-title>
        <v-breadcrumbs
          class="text-uppercase font-weight-bolder"
          :items="itemsLink"
        />
        <v-spacer />
      </v-card-title>

      <v-card-text
        class="ps-6 d-flex justify-space-between align-center flex-wrap"
        v-if="total != 0"
      >
        <div class="my-2">
          <h4 class="text-uppercase">
            {{ produto.categoria ? produto.categoria.designacao : "" }}
          </h4>
          <p class="gray--text text--darken-1 mb-0" v-if="produtos.length > 1">
            <span class="font-weight-bold"> {{ produtos.length }}</span>
            Produtos encontrados
          </p>

          <p class="gray--text text--darken-1 mb-0" v-else>
            <span class="font-weight-bold"> {{ produtos.length }}</span>
            Produto encontrado
          </p>
        </div>
        <div class="d-flex align-center flex-wrap">
          <div class="grey--text text--darken-1 me-2 my-2">Ordenar por:</div>
          <v-autocomplete
            class="border me-5 mt-6"
            outlined
            dense
            :items="ordersBy"
            item-value="valor"
            item-text="designacao"
            placeholder="Padrão"
            @change="orderByParameter()"
            v-model="params"
          />
          <div v-if="isWeb" class="grey--text text--darken-1 me-2 my-2">
            Ver:
          </div>
          <v-btn
            v-if="isWeb"
            @click="showDefaultList('default')"
            title="Ver Produtos em Forma de Grade"
            icon
          >
            <img
              v-svg-inline
              class="icon"
              src="@/assets/images/icons/grid.svg"
              alt=""
            />
          </v-btn>
          <v-btn
            v-if="isWeb"
            @click="showDefaultList('list')"
            title="Ver Produtos em Forma de Lista"
            icon
          >
            <img
              v-svg-inline
              class="icon"
              src="@/assets/images/icons/menu.svg"
              alt=""
            />
          </v-btn>
        </div>
      </v-card-text>
      <v-container class="mt-6 text-center">
        <v-card v-if="total == 0" elevation="0" max-height="300">
          <div>
            <h4>Não foram econtrados produtos associados a essa categoria!</h4>
            <v-btn
              color="primary"
              class="mt-2"
              text
              outlined
              @click="goToHome()"
            >
              Voltar</v-btn
            >
          </div>
        </v-card>
      </v-container>
      <v-col cols="12">
        <v-row class="mb-6">
          <v-divider />

          <v-col cols="12" v-if="loading">
            <v-progress-linear
              indeterminate
              color="secondary"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between mb-5">
          <h2 class="font-weight-bold" name="section-more-for-you"></h2>
          <!-- <router-link
              to="/portal/page/produtos/em-destaque"
              class="text-decoration-none blue-grey--text darken-1"
            >
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link> -->
        </div>
        <!-- <h2 class="text-uppercase">
            {{ produto.categoria ? produto.categoria.designacao : "" }}({{
              produtos.length
            }})
          </h2> -->

        <v-row v-if="defaultLayout == 'default'">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(item, index) in produtos"
            :key="index"
          >
            <CardCart
              cardHoverShadow
              :content-img="item.imagem_produto"
              :content-text="item.designacao"
              :counter="item.qty"
              @cartRemove="removeCart(item)"
              @cartAdd="addCart(item)"
              :amount="item.pvp"
              :produto="item"
            />
          </v-col>
        </v-row>

        <v-container v-else>
          <v-row name="list-products">
            <!-- First V-Card -->
            <v-col cols="12">
              <transition-group name="fade" mode="out-in">
                <v-card
                  elevation="0"
                  v-for="produto in produtos"
                  :key="produto.id"
                  class="mb-2"
                >
                  <v-card-text
                    class="ps-6 d-flex justify-space-between align-center flex-wrap"
                  >
                    <router-link
                      class="custom-link"
                      :to="{
                        name: 'ProdutoDetalhes',
                        params: { produto_id: produto.uuid },
                      }"
                    >
                      <v-list-item>
                        <v-list-item-avatar
                          size="80"
                          :title="produto.designacao"
                          rounded
                        >
                          <v-img
                            :alt="produto.designacao"
                            :src="SERVER + produto.imagem_produto"
                          ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-bold text-uppercase"
                            >{{ produto.designacao }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text-subtitle2 font-weight-medium"
                          >
                            {{ formatePrice(produto.pvp) }}
                            AKZ (IVA incluido)</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            <v-rating
                              :value="
                                produto.classificacao.num_classificacao ==
                                  null ||
                                !produto.classificacao.num_classificacao
                                  ? 5
                                  : parseInt(
                                      produto.classificacao.num_classificacao
                                    )
                              "
                              color="amber"
                              background-color="amber"
                              dense
                              half-increments
                              readonly
                              size="12"
                            ></v-rating>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- </transition-group> -->
                      </v-list-item>
                    </router-link>
                  </v-card-text>
                </v-card>
              </transition-group>
            </v-col>
          </v-row>
        </v-container>

        <v-row>
          <v-col cols="12" md="12">
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="last_page"
                :total-visible="1"
                @input="getOrdersLabels(page)"
                color="primary"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

  <!-- </div> -->
</template>

<script>
import CardCart from "@/components/cartCard/CardCart";
import { LOGIN_URL, SERVIDOR } from "@/configs/api";
import axios from "axios";

export default {
  components: {
    CardCart,
  },
  data() {
    return {
      isWeb: false,
      loadingOrdered: false,
      SERVER: SERVIDOR,
      defaultLayout: "default",
      last_page: 1,
      params: "",
      ordersBy: [],
      itemsLink: [
        {
          text: "Início",
          disabled: false,
          href: "/",
        },
        {
          text: "Todas Categorias",
          disabled: false,
          href: "#/portal/pages/categorias",
        },
        {
          text: "Categoria Selecionada",
          disabled: true,
          href: "/",
        },
      ],
      produto: {
        categoria: {},
      },
      loading: false,
      produtos: [],
      tab: null,
      total: null,
      page: 1,
      itemsPerPage: 8,
      items: [
        {
          img: require("@/assets/images/banners/cycle.png"),
          title: "Scarlett Beauty",
          avatar: require("@/assets/images/faces/2.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner.png"),
          title: "Scroll Through",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-3.png"),
          title: "Coveted Clicks",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-4.png"),
          title: "Constant Shoppers",
          avatar: require("@/assets/images/faces/5.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-5.png"),
          title: "Keyboard Kiosk",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner-6.png"),
          title: "Anytime Buys",
          avatar: require("@/assets/images/faces/9.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-7.png"),
          title: "Word Wide Wishes",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-8.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-9.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner.png"),
          title: "Scroll Through",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-3.png"),
          title: "Coveted Clicks",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-4.png"),
          title: "Constant Shoppers",
          avatar: require("@/assets/images/faces/5.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-5.png"),
          title: "Keyboard Kiosk",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner-6.png"),
          title: "Anytime Buys",
          avatar: require("@/assets/images/faces/9.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-7.png"),
          title: "Word Wide Wishes",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-8.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-9.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/6.png"),
        },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.getAllProdutos();
    this.isWeb =
      this.$vuetify.breakpoint.name === "xl" ||
      this.$vuetify.breakpoint.name === "lg" ||
      this.$vuetify.breakpoint.name === "md";
  },
  mounted() {
    this.getOrdersLabels();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    goToHome() {
      this.$router.push({
        name: "Home",
      });
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showDefaultList(params) {
      this.defaultLayout = params;
    },

    getOrdersLabels() {
      axios
        .get(`${LOGIN_URL}/listarOrderByProdutos`)
        .then((response) => {
          this.ordersBy = response.data;
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },
    orderByParameter() {
      this.loadingOrdered = true;
      var params = this.params;
      axios
        .get(
          LOGIN_URL +
            `/listarProdutosPelaCategoria/${this.produto.categoria.id}?orderBy=${params}`
        )
        .then((response) => {
          this.produtos = response.data.data;
          this.loadingOrdered = false;
        });
    },

    getAllProdutos() {
      this.loading = true;
      axios
        .get(
          LOGIN_URL +
            "/listarProdutosPelaCategoriaUuid/" +
            this.$route.params.uuid
        )
        .then((response) => {
          this.produtos = response.data.data;
          if (response.data.total == 0) {
            this.total = response.data.total;
            this.loading = false;
            // console.log("CATEGORIA SEM PRODUTO");
          }
          // alert(this.produtos);
          // alert(JSON.stringify(response.data.total));
          setTimeout(() => {
            // LISTAR PRODUTOS POR CATEGORIA BUG LOOP INFINITO
            // this.getAllProdutos();
            this.loading = false;
            this.produto = this.produtos[0];
          }, 50);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.alert.type = "error";
            this.loading = false;
            console.log("Erro de resposta do servidor:");
            console.log("Status:", error.response.status);
            // console.log("Dados de erro:", error.response.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
<style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style>
