<template>
  <div>
    <v-container>
      <v-card-title>
        <v-breadcrumbs
          class="text-uppercase font-weight-bolder"
          :items="items"
        />
        <v-spacer />
      </v-card-title>

      <v-divider />

      <v-card-text name="card-loading" v-if="loading">
        <v-row>
          <v-col cols="12" md="6">
            <v-col v-for="index in 3" :key="index++" cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto"
                max-width="700"
                max-height="80"
                type="card"
              />
            </v-col>
          </v-col>

          <v-col cols="12" md="6">
            <v-col v-for="index in 1" :key="index++" cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto"
                max-width="800"
                max-height="450"
                type="card"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="!loading">
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <v-row>
              <v-col :cols="isWeb ? '2' : '3'">
                <v-avatar
                  :class="!isWeb ? 'mt-4' : ''"
                  rounded
                  size="75"
                  color="secondary"
                >
                  <v-icon class="mt-1" color="white" size="60px">
                    mdi-map-marker-radius
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col :cols="isWeb ? '10' : '9'" class="text-h6 mt-2">
                Viana, Rua do Ginga Shopping, Junto a casa dos Frescos. <br />
                <v-divider class="mt-2 mb-2" />
                <span class="text-h6 grey--text"> Localização </span>
              </v-col>
            </v-row>

            <v-row link btn @click="makeCalling()">
              <v-col link btn @click="makeCalling()" :cols="isWeb ? '2' : '3'">
                <v-avatar
                  rounded
                  :class="!isWeb ? 'mt-4' : ''"
                  size="75"
                  color="secondary"
                >
                  <v-icon class="mt-1" color="white" size="60px">
                    mdi-phone
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col
                link
                btn
                @click="makeCalling()"
                :cols="isWeb ? '10' : '9'"
                class="text-h6 mt-2"
              >
                +244 934 66 00 03
                <br />
                <v-divider class="mt-2 mb-2" />
                <span class="text-h6 grey--text"> Telefone </span>
              </v-col>
            </v-row>
            <v-row link btn @click="sendMail()">
              <v-col link btn @click="sendMail()" :cols="isWeb ? '2' : '3'">
                <v-avatar rounded="" size="75" color="secondary">
                  <v-icon class="mt-1" color="white" size="60px">
                    mdi-email
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col
                link
                btn
                @click="sendMail()"
                :cols="isWeb ? '10' : '9'"
                class="text-h6 mt-2"
              >
                loja@mutue.net
                <br />
                <v-divider class="mt-2 mb-2" />
                <span class="text-h6 grey--text"> Email </span>
              </v-col>
            </v-row>
            <br />
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              :width="isWeb ? '450' : ''"
              class="justify-center text-center"
            >
              <div class="googlemap">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.474233522842!2d13.345936775814634!3d-8.928371491703588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f70dff02e19d%3A0x3ba5548c308396e3!2sMutue%20-%20Loja%20de%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2sao!4v1700127803762!5m2!1spt-PT!2sao"
                  :width="isWeb ? '600' : '350'"
                  :height="isWeb ? '450' : '250'"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <div class="px-3 px-md-10 py-8">
              <p class="text-14 mb-1 text-left">
                Nome Completo
              </p>
              <v-text-field v-model="user.name" outlined dense hide-details class="mb-4" placeholder="Nome Completo" />

              <p class="text-14 mb-1 text-left">
                Telefone
              </p>
              <v-text-field v-model="user.telefone" outlined dense hide-details class="mb-4" placeholder="923 11 22 33" />
              <p class="text-14 mb-1 text-left">
                Email
              </p>
              <v-text-field v-model="user.email" outlined dense hide-details placeholder="example@mail.com" class="mb-4" />
              <v-btn block color="secondary" class="text-uppercase font-600">
                Enviar Dados
              </v-btn>
            </div>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  //   metaInfo() {
  //   return {
  //     title: 'Contactos - Mutue Loja Online',
  //     meta: [
  //       {
  //         name: 'Contactos',
  //         content: 'A Mutue Loja Online está à sua disposição, pronta para transformar a maneira como você faz compras online. Imagine um mundo onde a busca pelo produto perfeito, a facilidade de compra e a satisfação do cliente se unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à Mutue!',
  //       },
  //     ],
  //   };
  // },
  data() {
    return {
      loading: false,
      isWeb: false,
      items: [
        {
          text: "Início",
          disabled: false,
          href: "/",
        },
        {
          text: "Contactos",
          disabled: true,
          href: "/",
        },
      ],

      user: {},
      tab: null,
      page: 1,
      itemsPerPage: 8,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.isWeb =
      this.$vuetify.breakpoint.name === "xl" ||
      this.$vuetify.breakpoint.name === "lg" ||
      this.$vuetify.breakpoint.name === "md";
  },

  mounted() {
    // Define um timeout de 15 segundos (15000ms)
    setTimeout(() => {
      this.loading = false; // Altera o estado do loading para false após 15 segundos
    }, 1500);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    sendMail() {
      let email = "loja@mutue.net";
      window.open("mailto:" + email);
    },
    makeCalling() {
      let telefone = "934660003";
      window.open("tel:" + telefone);
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
