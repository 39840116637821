<template>
  <v-container class="py-6">
    <v-card-text name="card-loading" v-if="loading">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            class="mx-auto"
            max-width="250"
            max-height="170"
            type="card"
          />
        </v-col>

        <v-col cols="12" md="6" class="justify-left text-left">
          <v-col cols="12" md="12" v-for="index in 2" :key="index++">
            <v-skeleton-loader
              class="mx-auto"
              max-width="850"
              max-height="80"
              type="card"
            />
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{ open: isSidebar }"
            @click="isSidebar = !isSidebar"
          />
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <dashbord-sidebar-two />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center">
                  <v-avatar tile size="25" class="me-3">
                    <img src="@/assets/images/icons/user_filled.svg" alt="" />
                  </v-avatar>
                  <h2 class="mb-0">Meu Perfil</h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="dialogUserPerfil = true"
                >
                  Editar Meu Perfil
                </v-btn>
              </div>

              <v-row>
                <v-col cols="12" md="12">
                  <base-card>
                    <div class="pa-5">
                      <div
                        class="d-flex justify-space-between align-center flex-wrap"
                      >
                        <div class="d-flex align-center">
                          <v-avatar size="64" class="me-4">
                            <img
                              src="@/assets/images/faces/default-avatar.png"
                              alt=""
                            />
                          </v-avatar>
                          <div>
                            <h4 class="font-600">
                              {{ user ? user.name : "" }}
                            </h4>
                            <p
                              class="mb-0 grey--text text--darken-1"
                              v-if="user.cartaoCliente"
                            >
                              Saldo:
                              <span class="primary--text">
                                {{
                                  user.cartaoCliente
                                    ? user.cartaoCliente.saldo
                                    : ""
                                }}
                                KZ</span
                              >
                            </p>
                          </div>
                        </div>
                        <!-- <p class="mb-0 grey--text text--darken-1">
                          SILVER USER
                        </p> -->
                      </div>
                    </div>
                  </base-card>
                </v-col>

                <!-- <v-col cols="6" sm="6" md="4" lg="2">
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      16
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Todos Pedidos
                    </p>
                  </base-card>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="2">
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      02
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Awaiting Payments
                    </p>
                  </base-card>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="2">
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      02
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Awaiting Shipment
                    </p>
                  </base-card>
                </v-col>
                <v-col cols="6" sm="6" md="4" lg="2">
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      01
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Aguardando Entrega
                    </p>
                  </base-card>
                </v-col> -->

                <v-col cols="12">
                  <base-card>
                    <div class="pa-4 d-flex justify-space-between flex-wrap">
                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Nome Completo
                        </p>
                        <span>{{ user ? user.name : "" }}</span>
                      </div>

                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Email
                        </p>
                        <span>{{ user ? user.email : "" }}</span>
                      </div>
                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Telefone
                        </p>
                        <span>{{ user ? user.telefone : "" }} </span>
                      </div>
                    </div>
                  </base-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      name="dialog-logout-sucesso"
      v-model="loading"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-col cols="12">
            <span> Carregando dados...</span>
          </v-col>
          <v-progress-linear
            indeterminate
            color="secondary"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogUserPerfil"
      scrollable
      max-width="800"
      persistent
      name="dialog-user-perfil"
    >
      <v-card v-if="!loading">
        <v-card-title class="secondary white--text">
          {{ user.username }} | {{ user.telefone }}
          <v-spacer />
          <v-btn icon text elevation="1" @click="dialogUserPerfil = false">
            <v-icon medium class="white--text"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />

        <v-card-text>
          <!-- <v-progress-linear indeterminate color="secondary"></v-progress-linear> -->
          <v-container>
            <!-- <h3>{{ user }}</h3> -->
            <v-row class="mt-2">
              <v-col cols="12" md="12">
                <v-text-field
                  dense
                  v-model="user.name"
                  prepend-icon="mdi-account"
                  label="Nome Próprio"
                  placeholder="Nome Próprio"
                  outlined
                />
              </v-col>
            </v-row>

            <v-row class="name" style="margin-top: -30px">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="endereco"
                  dense
                  prepend-icon="mdi-map-marker"
                  label="Endereço"
                  placeholder="Endereço"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row class="name">
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  v-model="user.telefone"
                  prepend-icon="mdi-phone"
                  label="Telefone principal"
                  placeholder="923 00 11 22"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  v-model="user.email"
                  prepend-icon="mdi-email"
                  label="Email Principal"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row class="name">
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  v-model="user.password1"
                  :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  prepend-icon="mdi-lock"
                  label="Password"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  v-model="user.password2"
                  :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  prepend-icon="mdi-lock"
                  label="Password"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary darken-1"
            outlined
            @click="dialogUserPerfil = false"
          >
            Sair
          </v-btn>
          <v-btn color="secondary darken-1" @click="updateUserData()">
            Salvar Alterações
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
        <v-alert
          style="color: red"
          dismissible
          transition="scale-transition"
          outlined
          dense
          v-if="alert.type == 'error'"
          type="error"
        >
          {{ errors.message }}
        </v-alert>
      </v-card>
      <!-- <v-card v-else>
        <div class="text-center mt-12">
          <v-progress-circular size="50" indeterminate color="primary" />
          <br>
          <span class="text-center"> Atualizando... </span>
        </div>
      </v-card> -->
    </v-dialog>
    <v-dialog
      name="dialog-logout-sucesso"
      v-model="dialogUserPerfilUpdated"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50"
            >mdi-check-circle</v-icon
          >
          <h6 class="mt-2 text-uppercase">sucesso</h6>

          <v-col cols="12">
            <!-- <span>Perfil Atualizado com sucesso!</span> -->
            <span>
              {{ message }}
            </span>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            outlined
            color="green"
            class="white--text ml-2"
            @click="requestData()"
            dense
          >
            FECHAR POPUP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { BASE_URL, LOGIN_URL } from "@/configs/api";
import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
import axios from "axios";
import { set } from "vue";
export default {
  components: {
    DashbordSidebarTwo,
  },

  data() {
    return {
      dialogUserPerfilUpdated: false,
      alert: {
        text: "",
        type: "",
        text2: "",
        type2: "",
      },
      showpassword: false,
      message: "",
      endereco: "",
      userLogado: {
        user: {},
      },
      loading: false,
      dialogUserPerfil: false,
      user: {
        cliente: {
          endereco: "",
        },
        cartaoCliente: {},
      },
      isSidebar: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
    };
  },
  mounted() {
    this.getUserAuth();
    this.checkUserIsAuth();
  },
  methods: {
    requestData() {
      //  this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/user/meAuth`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.user = response.data;
          // localStorage.setItem("TOKEN", response.data.token);
          let userLogado = JSON.stringify(response.data);
          localStorage.setItem("userLogado", userLogado);

          // setTimeout(() => {
          //   this.loading = false;
          // }, 100);
        });

      setTimeout(() => {
        this.dialogUserPerfilUpdated = false;
      }, 550);
    },
    getUserAuth() {
      // const userLogadoString = localStorage.getItem('userLogado')
      // if (userLogadoString) {
      //     this.userLogado = JSON.parse(userLogadoString)
      //     this.user = this.user
      // }
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/user/meAuth`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.user = response.data;
        });
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    updateUserData() {
      // alert("UPDATING USER DATA");
      let user = this.user;
      this.loading = true;
      let formData = new FormData();
      var utilizador = {};
      utilizador.id = user.id;
      utilizador.uuid = user.uuid;
      utilizador.telefone = user.telefone;
      utilizador.name = user.name;
      utilizador.username = user.username;
      utilizador.email = user.email;
      utilizador.password1 = user.password1;
      utilizador.password2 = user.password2;
      utilizador.endereco = this.endereco;

      formData.append("foto", this.userFoto);
      formData.append("user", JSON.stringify(utilizador));
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");

      const url = `${LOGIN_URL}/user/editar/${this.user.uuid}`;
      this.alert.type = null;
      this.alert.text = null;

      axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          // alert(JSON.stringify(response.data));
          // this.dialogUserPerfil = false;
          this.message = response.data.message;
          this.loading = false;
          console.log(response.data);
          this.dialogUserPerfil = false;
          this.dialogUserPerfilUpdated = true;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.error) {
            this.alert.type = "error";
            setTimeout(() => {
              this.loading = false;
            }, 800);
            this.message = error.response.data.error;
            if (error.response.status == 400) {
              alert(JSON.stringify(error.response.data));
              console.log("Status de erro:", this.message);
            }
          }
        });
    },
    checkUserIsAuth() {
      if (localStorage.getItem("TOKEN") == null) {
        this.$router.push("/");
      }
    },
  },
};
</script>
