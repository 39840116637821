<template>
  <div>
    <v-container>
      <v-card-title>
        <v-breadcrumbs
          class="text-uppercase font-weight-bolder"
          :items="items"
        />
        <v-spacer />
      </v-card-title>
      <!-- <h2 class="mb-6 text-uppercase">Perguntas Frequentes</h2> -->
      <v-divider />
      <!-- <v-col cols="12">
        <v-progress-linear v-if="loading" color="secondary accent-4" indeterminate rounded dense height="2" />
      </v-col> -->
      <v-card-text v-if="loading" name="card-loading">
        <v-row>
          <v-col v-for="index in 8" :key="index++" cols="12" md="6">
            <v-skeleton-loader
              class="mx-auto"
              max-width="700"
              max-height="50"
              type="card"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-row v-if="!loading">
        <v-col
          v-for="(faq, index) in perguntas_frequentes"
          :key="index++"
          cols="12"
          md="6"
        >
          <v-card id="format-card-curve" elevation="0">
            <v-expansion-panels elevation="0">
              <v-expansion-panel id="format-card-curve">
                <v-expansion-panel-header class="font-weight-medium">
                  {{ index }}. {{ faq.pergunta }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-justify">
                  {{ faq.resposta }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <br> -->
            </v-expansion-panels>
          </v-card>
          <!-- <br> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { BASE_URL } from "@/configs/api";
import axios from "axios";

export default {
  components: {},
  //     metaInfo() {
  //   return {
  //     title: 'Perguntas Frequentes - Mutue Loja Online',
  //     meta: [
  //       {
  //         name: 'Perguntas Frequentes',
  //         content: 'A Mutue Loja Online está à sua disposição, pronta para transformar a maneira como você faz compras online. Imagine um mundo onde a busca pelo produto perfeito, a facilidade de compra e a satisfação do cliente se unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à Mutue!',
  //       },
  //    ],
  //   };
  // },
  data() {
    return {
      userLogado: null,
      loading: false,
      perguntas_frequentes: [],
      items: [
        {
          text: "Início",
          disabled: false,
          href: "/",
        },
        {
          text: "Perguntas frequentes",
          disabled: true,
          href: "/",
        },
      ],
      tab: null,
      page: 1,
      itemsPerPage: 8,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.getFaqs();

    const userLogadoString = localStorage.getItem("userLogado");
    if (userLogadoString) {
      this.userLogado = JSON.parse(userLogadoString);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // http://172.10.15.71:8000/api/portal/empresa/perguntas/frequentes
    getFaqs() {
      this.loading = true;
      const url = `${BASE_URL}/empresa/perguntas/frequentes`;
      axios.get(url).then((response) => {
        this.perguntas_frequentes = response.data.data;
        // alert(JSON.stringify(response.data.data));
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
